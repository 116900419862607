<template>
  <section class="project--detail-nearby" v-if="nearbyPlaces && nearbyPlaces.length > 0">
    <h2 class="title--text">
      {{ $t('general.nearbyPlaces') }}
    </h2>
    <div class="column--wrapper">
      <div
        class="column--content"
        v-for="(place, index) in nearbyPlaces"
        :key="'nearby-place-' + index"
      >
        <v-icon>mdi-map-marker</v-icon>
        <div class="column--content-right ml-2">
          <div class="column--desc">
            <span>{{ place.name }}</span>
            <span style="min-width: 70px; text-align: right">{{ place.distance_in_km }} km</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'project-detail-detail',
  props: ['nearbyPlaces'],
  methods: {},
};
</script>
