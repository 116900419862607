<template>
  <div
    class="project--detail-attachment"
    v-show="project.youtube_url || (galleries && galleries.length > 0)"
  >
    <div class="attachment" v-if="project.youtube_url" @click="$modal.show('youtube-modal')">
      <img class="attachment--bg" :src="`//img.youtube.com/vi/${youtubeId}/0.jpg`" />
      <div class="attachment--bg-overlay"></div>
      <div class="attachment--content">Video</div>
    </div>
    <div
      class="attachment"
      v-if="galleries && galleries.length > 0 && galleries[0]"
      @click="clickImage(0)"
    >
      <picture>
        <source :srcset="galleries[0].url" type="image/webp" />
        <source :srcset="getFallbackImage(galleries[0].url)" type="image/jpeg" />
        <img
          :src="getFallbackImage(galleries[0].url)"
          class="attachment--bg"
          :alt="`Denah ${project.project_title} - BeliRumah`"
        />
      </picture>
      <div class="attachment--bg-overlay"></div>
      <div class="attachment--content">Floor Plans</div>
    </div>
    <!--    <a-->
    <!--      class="attachment"-->
    <!--      v-if="project.brochure_url"-->
    <!--      :href="project.brochure_url"-->
    <!--      target="_blank"-->
    <!--      style="text-decoration: none;"-->
    <!--    >-->
    <!--      <div class="attachment&#45;&#45;bg-icon">-->
    <!--        <v-icon>mdi-file-document-outline</v-icon>-->
    <!--      </div>-->
    <!--      <div class="attachment&#45;&#45;bg-overlay"></div>-->
    <!--      <div class="attachment&#45;&#45;content">{{ $t('general.downloadBrochure') }}</div>-->
    <!--    </a>-->
    <client-only>
      <vue-gallery-slideshow
        :images="galleries"
        :index="imageIndex"
        @close="clickImage(null)"
      ></vue-gallery-slideshow>
    </client-only>
  </div>
</template>

<script>
const VueGallerySlideshow = () => import('vue-gallery-slideshow');
import { mapState, mapGetters } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'project-detail-attachment',
  mixins: [HelperMixin],
  components: {
    VueGallerySlideshow,
  },
  data() {
    return {
      youtubeEmbedUrl: null,
      youtubeId: null,
    };
  },
  props: ['project'],
  methods: {
    clickImage(index) {
      this.$store.commit('v2/project/set_floor_plan_index', index);
    },
  },
  computed: {
    ...mapState({
      imageIndex: (state) => state.v2.project.floorPlanIndex,
      baseUrl: (state) => state.global.baseUrl,
      isLoading: (state) => state.global.isLoading,
    }),
    ...mapGetters({
      galleries: 'v2/project/floorPlanGalleries',
    }),
  },
  async mounted() {
    this.youtubeEmbedUrl = await this.$store.dispatch(
      'v2/project/getYoutubeEmbedUrl',
      this.project.youtube_url,
    );
    this.youtubeId = await this.$store.dispatch(
      'v2/project/getYoutubeId',
      this.project.youtube_url,
    );
  },
  beforeDestroy() {
    this.$store.commit('v2/project/set_floor_plan_index', null);
  },
};
</script>
