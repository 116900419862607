<template>
  <section class="project--detail-action">
    <div class="contact--label" v-if="project.listing_type === listingTypeEnum.PRIMARY">
      <img :src="project.developer_photo" :alt="project.developer_name" />
      <span>{{ project.developer_name }}</span>
      <!--      <router-link :to="project.links.owner">-->
      <!--        <span>{{ project.owner.fullname }}</span>-->
      <!--      </router-link>-->
    </div>
    <div
      class="contact--label"
      v-else-if="project.listing_type === listingTypeEnum.SECONDARY && project.owner"
    >
      <router-link :to="`${project.owner.catalog_url}?listing_type_id=2`">
        <img
          :src="project.owner.photo_profile_user"
          :alt="project.owner.name"
          class="agent--image"
        />
        <div class="agent--name mt-2">{{ project.owner.name }}</div>
      </router-link>
      <div class="agent--type mt-2" v-if="project.owner.nib">
        {{ `${$t('general.nib')}: ${project.owner.nib}` }}
      </div>
      <div
        class="d-flex align-items-center justify-content-between mt-2"
        v-if="project.owner.agent_office_logo || project.owner.agent_type_id === 1"
      >
        <div class="agent--type agent--type-office" v-if="project.owner.agent_type">
          {{ project.owner.agent_type }}
        </div>
        <router-link
          :to="`${project.owner.catalog_url}?listing_type_id=2`"
          :title="
            project.owner.agent_type_id === 1
              ? project.owner.agent_type
              : project.owner.agent_office
          "
        >
          <div class="agent--office">
            <div class="agent--office-logo" v-if="project.owner.agent_office_logo">
              <img :src="project.owner.agent_office_logo" :alt="project.owner.agent_office" />
            </div>
            <div class="agent--office-logo" v-else-if="project.owner.agent_type_id === 1">
              <img :src="agentOfficeIndependent" :alt="project.owner.agent_type" />
            </div>
            <!--          <div>-->
            <!--            {{ project.owner.agent_office }}-->
            <!--          </div>-->
          </div>
        </router-link>
      </div>
      <div v-else>
        <div class="agent--type mt-2" v-if="project.owner.agent_type">
          {{ project.owner.agent_type }}
        </div>
      </div>
    </div>
    <contact-button
      :type="type"
      :name="project.project_name"
      :agentUuid="agentUuid"
      :listingUuid="listingUuid"
      :price="project.min_price_num"
      :contactNumber="
        project.listing_type === listingTypeEnum.SECONDARY && project.owner
          ? project.owner.phone_number
          : null
      "
      :ownerName="project.owner ? project.owner.name : ''"
      v-if="!isLoggedIn || !isOwner"
    ></contact-button>
    <div class="button--wrapper">
      <div class="btn" @click="toggleShortlist">
        <img
          v-if="shortlisted"
          src="@/assets/img/icons/favorite-filled.svg"
          class="favorite--filled"
        />
        <img v-else src="@/assets/img/icons/favorite.svg" alt="Favorite Outline" />
        <div>{{ $t('general.save') }}</div>
      </div>
      <share></share>
    </div>
    <div v-if="isLoggedIn && isOwner">
      <not-active :project="project"></not-active>
      <owner-section
        class="owner--section"
        :project="project"
        :checkBankAccountDone="checkBankAccountDone"
      />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Share from '@/components/utils/share';
const OwnerSection = () => import('@/components/project-detail/owner-section');
const ContactButton = () => import('@/components/project-detail/partials/contact-button');
const NotActive = () => import('@/components/project-detail/not-active');

export default {
  name: 'project-detail-action',
  props: ['project', 'type', 'agentUuid', 'listingUuid'],
  components: {
    Share,
    OwnerSection,
    ContactButton,
    NotActive,
  },
  data() {
    return {
      checkBankAccountDone: false,
    };
  },
  computed: {
    ...mapState({
      agentOfficeIndependent: (state) => state.global.agentOfficeIndependent,
      isLoggedIn: (state) => state.global.loggedIn,
      shortlists: (state) => state.v2.search.shortlists,
      listingTypeEnum: (state) => state.v2.masters.listingTypeEnum,
    }),
    shortlisted() {
      if (this.shortlists && this.shortlists.length === 0) {
        return false;
      } else {
        return this.shortlists.indexOf(this.project.uuid) !== -1;
      }
    },
    isOwner() {
      if (this.$store.state.global.user != null && this.project.owner != null) {
        return this.$store.state.global.user.uuid == this.project.owner.uuid;
      }
      return false;
    },
  },
  methods: {
    async toggleShortlist() {
      if (this.isLoggedIn) {
        let data = {};
        if (this.project.listing_type === this.listingTypeEnum.SECONDARY) {
          data.listingUuid = this.project.uuid;
        } else {
          data.projectUuid = this.project.uuid;
        }
        let response = await this.$store.dispatch('v2/project/addOrRemoveShortlist', data);
        await this.$store.commit('v2/search/set_shortlists', response);
      } else {
        this.$modal.show('modal-login');
      }
    },
  },
};
</script>
