<template>
  <section class="project--detail-banner">
    <client-only>
      <vue-gallery-slideshow
        :images="normalGalleries"
        :index="imageIndex"
        @close="clickImage(null)"
        v-if="normalGalleries && normalGalleries.length > 0"
      ></vue-gallery-slideshow>
      <VueSlickCarousel
        class="carousel--wrapper carousel--desktop"
        v-bind="settings"
        @beforeChange="beforeChange"
        @afterChange="afterChange"
        v-if="normalGalleries && normalGalleries.length > 0"
      >
        <div
          :key="`gallery-0`"
          class="carousel--slide wrapper--gallery-grid first--image"
          ref="gallery"
        >
          <div
            class="img--gallery mb-0"
            :style="`background-image: url('${firstGallery}'), url('${getFallbackImage(
              firstGallery,
            )}');`"
            @click="clickImage(0)"
            v-if="!project.pano_embed_url"
          ></div>
          <div class="img--gallery mb-0" v-else>
            <iframe
              v-if="validUrl(project.pano_embed_url)"
              class="pano--gallery"
              :src="project.pano_embed_url"
              allowfullscreen="allowfullscreen"
            ></iframe>
          </div>
        </div>
        <div
          v-for="(gallery, index) in galleries"
          :key="`gallery-${index + 1}`"
          class="carousel--slide wrapper--gallery-grid"
          ref="gallery"
        >
          <div
            class="img--gallery"
            :class="{ 'img--blank': !gallery[0].item }"
            :style="`background-image: url('${
              forceBlankHDImage(gallery[0].item)
                ? forceBlankHDImage(gallery[0].item)
                : '/img/blank.png'
            }'), url('${
              forceBlankHDImage(gallery[0].item)
                ? getBlankFallbackImage(forceBlankHDImage(gallery[0].item))
                : '/img/blank.png'
            }');`"
            @click="clickImage(gallery[0].index + (!project.pano_embed_url ? 1 : 0))"
          ></div>
          <div
            class="img--gallery mb-0"
            :class="{ 'img--blank': !gallery[1].item }"
            :style="`background-image: url('${
              forceBlankHDImage(gallery[1].item)
                ? forceBlankHDImage(gallery[1].item)
                : '/img/blank.png'
            }'), url('${
              forceBlankHDImage(gallery[1].item)
                ? getBlankFallbackImage(forceBlankHDImage(gallery[1].item))
                : '/img/blank.png'
            }');`"
            @click="clickImage(gallery[1].index + (!project.pano_embed_url ? 1 : 0))"
          ></div>
        </div>
        <template #prevArrow>
          <div class="custom-prev--arrow">
            <i class="ion-chevron-left"></i>
          </div>
        </template>
        <template #nextArrow>
          <div class="custom-next--arrow">
            <i class="ion-chevron-right"></i>
          </div>
        </template>
      </VueSlickCarousel>
      <VueSlickCarousel
        class="carousel--wrapper carousel--mobile"
        v-bind="settings"
        @beforeChange="beforeChange"
        @afterChange="afterChange"
        v-if="normalGalleries && normalGalleries.length > 0"
      >
        <div
          v-for="(gallery, index) in normalGalleries"
          :key="`gallery-${index}`"
          class="carousel--slide wrapper--gallery-grid"
          ref="gallery"
        >
          <div
            class="img--gallery mb-0"
            :style="`background-image: url('${forceBlankHDImage(
              gallery,
            )}'), url('${getBlankFallbackImage(forceBlankHDImage(gallery))}');`"
            @click="clickImage(index)"
          ></div>
        </div>
        <template #prevArrow>
          <div class="custom-prev--arrow">
            <i class="ion-chevron-left"></i>
          </div>
        </template>
        <template #nextArrow>
          <div class="custom-next--arrow">
            <i class="ion-chevron-right"></i>
          </div>
        </template>
      </VueSlickCarousel>
    </client-only>
  </section>
</template>

<script>
const VueGallerySlideshow = () => import('vue-gallery-slideshow');
import HelperMixin from '@/mixins/helpers';
export default {
  name: 'project-detail-banner',
  mixins: [HelperMixin],
  props: ['project', 'store', 'imageIndex', 'normalGalleries'],
  components: {
    VueGallerySlideshow,
  },
  data: () => ({
    dragging: false,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      dots: false,
      edgeFriction: 0.35,
      speed: 500,
      draggable: false,
      infinite: true,
      responsive: [
        {
          breakpoint: 1281,
          settings: {
            variableWidth: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            variableWidth: false,
          },
        },
        {
          breakpoint: 426,
          settings: {
            variableWidth: false,
          },
        },
      ],
    },
  }),
  computed: {
    firstGallery: {
      get() {
        let tempGalleries = this.$store.getters[this.store + '/galleries'];
        let gallery = {};
        if (tempGalleries && tempGalleries.length > 0) {
          gallery = tempGalleries[0];
        }
        return this.forceBlankHDImage(gallery);
      },
      // eslint-disable-next-line no-unused-vars
      set(val) {},
    },
    galleries: {
      get() {
        let tempGalleries = JSON.parse(
          JSON.stringify(this.$store.getters[this.store + '/galleries']),
        );
        let newGalleries = [];
        if (tempGalleries && tempGalleries.length > 0) {
          if (!this.project.pano_embed_url) tempGalleries.shift();
          for (let i = 0; i < tempGalleries.length; i += 2) {
            if (i + 1 < tempGalleries.length) {
              newGalleries.push([
                {
                  index: i,
                  item: tempGalleries[i],
                },
                {
                  index: i + 1,
                  item: tempGalleries[i + 1],
                },
              ]);
            } else {
              newGalleries.push([
                {
                  index: i,
                  item: tempGalleries[i],
                },
                {
                  index: i + 999,
                  item: null,
                },
              ]);
              break;
            }
          }
          if (newGalleries.length < 2) {
            newGalleries = this.pushEmptyGallery(newGalleries);
          }
          if (newGalleries.length < 3) {
            newGalleries = this.pushEmptyGallery(newGalleries);
          }
        }
        return newGalleries;
      },
      // eslint-disable-next-line no-unused-vars
      set(val) {},
    },
  },
  methods: {
    clickImage(index) {
      if (index < this.normalGalleries.length) {
        this.$store.commit(this.store + '/set_image_index', index);
      }
    },
    pushEmptyGallery(galleries) {
      galleries.push([
        {
          index: galleries.length + 999,
          item: null,
        },
        {
          index: galleries.length + 1000,
          item: null,
        },
      ]);
      return galleries;
    },
    beforeChange() {
      this.dragging = true;
    },
    afterChange() {
      this.dragging = false;
    },
    clickHandler(e) {
      if (this.dragging) {
        e.preventDefault();
      }
    },
  },
};
</script>
