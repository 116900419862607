<template>
  <div>
    <v-menu offset-y offset-x content-class="share--popup-container">
      <template v-slot:activator="{ on, attrs }">
        <div id="shareBtn" class="btn" v-bind="attrs" v-on="on">
          <img class="share--icon" src="@/assets/img/icons/share.svg" alt="Bagikan" />
          <div>{{ $t('general.share') }}</div>
        </div>
      </template>
      <vue-goodshare-facebook
        class="popup--item share--fb"
        button_design=""
        :page_url="socialUrl"
        has_icon
      ></vue-goodshare-facebook>
      <vue-goodshare-twitter
        class="popup--item share--tw"
        button_design=""
        :page_url="socialUrl"
        has_icon
      ></vue-goodshare-twitter>
      <!--    <vue-goodshare-whatsapp-->
      <!--      class="popup&#45;&#45;item share&#45;&#45;wa"-->
      <!--      button_design=""-->
      <!--      :page_url="socialUrl"-->
      <!--      has_icon-->
      <!--    ></vue-goodshare-whatsapp>-->
      <a class="button-social popup--item share--wa" @click.prevent="openWA">
        <img src="@/assets/img/icons/whatsapp-1.svg" alt="Whatsapp" />
      </a>
      <a class="button-social popup--item share--link" @click="copyToClipboard(socialUrl)">
        <v-icon>mdi-link-variant</v-icon>
      </a>
    </v-menu>
    <v-snackbar v-model="copySucceeded">
      <div>
        {{ $t('general.success') }} <strong> {{ $t('general.clipboard') }}</strong>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
const VueGoodshareFacebook = () => import('vue-goodshare/src/providers/Facebook.vue');
const VueGoodshareTwitter = () => import('vue-goodshare/src/providers/Twitter.vue');
// const VueGoodshareWhatsapp = () => import('vue-goodshare/src/providers/WhatsApp.vue');
export default {
  components: {
    VueGoodshareFacebook,
    VueGoodshareTwitter,
    // VueGoodshareWhatsapp,
  },
  data: () => ({
    copySucceeded: false,
  }),
  computed: {
    socialUrl() {
      return this.$store.state.global.baseUrl + this.$route.fullPath;
    },
  },
  methods: {
    openWA() {
      window.open('https://wa.me/?text=' + encodeURI(this.socialUrl), '_blank');
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.handleCopy(true);
    },
    async handleCopy(status) {
      this.copySucceeded = status;
      let self = this;
      setTimeout(() => {
        self.copySucceeded = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.button-social {
  display: inline-flex;
  cursor: pointer;
  padding: 7px 10px;
  margin: 3px 1.5px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
.share--wa {
  img {
    width: 26px;
  }
}
</style>
