<template>
  <section class="project--detail-similar">
    <h2 class="title--text">
      <span v-if="project.listing_type === listingTypeEnum.PRIMARY">{{
        $t('general.similar')
      }}</span>
      <span v-else>{{ $t('general.similarProperty') }}</span>
    </h2>
    <div v-if="listProject && listProject.length > 0">
      <slick-projects :contents="listProject" :slidesPerRow="3" :type="project.listing_type" />
    </div>
  </section>
</template>

<script>
import SlickProjects from '@/components/home/partials/slick-projects';
import { mapState } from 'vuex';
export default {
  props: ['uuid', 'project'],
  components: {
    SlickProjects,
  },
  data() {
    return {
      listProject: [],
    };
  },
  computed: {
    ...mapState({
      listingTypeEnum: (state) => state.v2.masters.listingTypeEnum,
    }),
  },
  watch: {
    uuid(val) {
      console.log('NEW PROJECT UUID: ', val);
      this.getSimilarProject();
    },
  },
  methods: {
    async getSimilarProject() {
      try {
        let data = {};
        if (this.project.listing_type === this.listingTypeEnum.SECONDARY) {
          data.listingUuid = this.project.uuid;
        } else {
          data.projectUuid = this.project.uuid;
        }
        data.subsidized = this.project.subsidized;
        this.listProject = await this.$store.dispatch('v2/search/getSimilar', data);
      } catch (e) {
        console.log('ERROR GETTING SIMILAR PROJECT: ', e);
      }
    },
  },
  mounted() {
    this.getSimilarProject();
  },
};
</script>
