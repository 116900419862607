<template>
  <section class="project--detail-desc" v-show="description">
    <h2 class="title--text">
      <span v-if="listingType === listingTypeEnum.PRIMARY">{{ $t('general.projectDesc') }}</span>
      <span v-else>{{ $t('general.propertyDesc') }}</span>
    </h2>
    <div class="desc" :class="{ 'hide--all': !seeAll }">
      <div class="overlay"></div>
      {{ description }}
    </div>
    <div class="see--all" @click="switchSeeAll()">
      <span v-show="seeAll === false">{{ $t('general.showMore') }}</span>
      <span v-show="seeAll === true">{{ $t('general.showLess') }}</span>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'project-detail-description',
  props: ['description', 'listingType'],
  data: () => ({
    seeAll: false,
  }),
  computed: {
    ...mapState({
      listingTypeEnum: (state) => state.v2.masters.listingTypeEnum,
    }),
  },
  methods: {
    switchSeeAll() {
      this.seeAll = !this.seeAll;
    },
  },
};
</script>
