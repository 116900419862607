var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"project--detail-banner"},[_c('client-only',[(_vm.normalGalleries && _vm.normalGalleries.length > 0)?_c('vue-gallery-slideshow',{attrs:{"images":_vm.normalGalleries,"index":_vm.imageIndex},on:{"close":function($event){return _vm.clickImage(null)}}}):_vm._e(),(_vm.normalGalleries && _vm.normalGalleries.length > 0)?_c('VueSlickCarousel',_vm._b({staticClass:"carousel--wrapper carousel--desktop",on:{"beforeChange":_vm.beforeChange,"afterChange":_vm.afterChange},scopedSlots:_vm._u([{key:"prevArrow",fn:function(){return [_c('div',{staticClass:"custom-prev--arrow"},[_c('i',{staticClass:"ion-chevron-left"})])]},proxy:true},{key:"nextArrow",fn:function(){return [_c('div',{staticClass:"custom-next--arrow"},[_c('i',{staticClass:"ion-chevron-right"})])]},proxy:true}],null,false,1320227186)},'VueSlickCarousel',_vm.settings,false),[_c('div',{key:`gallery-0`,ref:"gallery",staticClass:"carousel--slide wrapper--gallery-grid first--image"},[(!_vm.project.pano_embed_url)?_c('div',{staticClass:"img--gallery mb-0",style:(`background-image: url('${_vm.firstGallery}'), url('${_vm.getFallbackImage(
            _vm.firstGallery,
          )}');`),on:{"click":function($event){return _vm.clickImage(0)}}}):_c('div',{staticClass:"img--gallery mb-0"},[(_vm.validUrl(_vm.project.pano_embed_url))?_c('iframe',{staticClass:"pano--gallery",attrs:{"src":_vm.project.pano_embed_url,"allowfullscreen":"allowfullscreen"}}):_vm._e()])]),_vm._l((_vm.galleries),function(gallery,index){return _c('div',{key:`gallery-${index + 1}`,ref:"gallery",refInFor:true,staticClass:"carousel--slide wrapper--gallery-grid"},[_c('div',{staticClass:"img--gallery",class:{ 'img--blank': !gallery[0].item },style:(`background-image: url('${
            _vm.forceBlankHDImage(gallery[0].item)
              ? _vm.forceBlankHDImage(gallery[0].item)
              : '/img/blank.png'
          }'), url('${
            _vm.forceBlankHDImage(gallery[0].item)
              ? _vm.getBlankFallbackImage(_vm.forceBlankHDImage(gallery[0].item))
              : '/img/blank.png'
          }');`),on:{"click":function($event){_vm.clickImage(gallery[0].index + (!_vm.project.pano_embed_url ? 1 : 0))}}}),_c('div',{staticClass:"img--gallery mb-0",class:{ 'img--blank': !gallery[1].item },style:(`background-image: url('${
            _vm.forceBlankHDImage(gallery[1].item)
              ? _vm.forceBlankHDImage(gallery[1].item)
              : '/img/blank.png'
          }'), url('${
            _vm.forceBlankHDImage(gallery[1].item)
              ? _vm.getBlankFallbackImage(_vm.forceBlankHDImage(gallery[1].item))
              : '/img/blank.png'
          }');`),on:{"click":function($event){_vm.clickImage(gallery[1].index + (!_vm.project.pano_embed_url ? 1 : 0))}}})])})],2):_vm._e(),(_vm.normalGalleries && _vm.normalGalleries.length > 0)?_c('VueSlickCarousel',_vm._b({staticClass:"carousel--wrapper carousel--mobile",on:{"beforeChange":_vm.beforeChange,"afterChange":_vm.afterChange},scopedSlots:_vm._u([{key:"prevArrow",fn:function(){return [_c('div',{staticClass:"custom-prev--arrow"},[_c('i',{staticClass:"ion-chevron-left"})])]},proxy:true},{key:"nextArrow",fn:function(){return [_c('div',{staticClass:"custom-next--arrow"},[_c('i',{staticClass:"ion-chevron-right"})])]},proxy:true}],null,false,1320227186)},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.normalGalleries),function(gallery,index){return _c('div',{key:`gallery-${index}`,ref:"gallery",refInFor:true,staticClass:"carousel--slide wrapper--gallery-grid"},[_c('div',{staticClass:"img--gallery mb-0",style:(`background-image: url('${_vm.forceBlankHDImage(
            gallery,
          )}'), url('${_vm.getBlankFallbackImage(_vm.forceBlankHDImage(gallery))}');`),on:{"click":function($event){return _vm.clickImage(index)}}})])}),0):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }