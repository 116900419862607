<template>
  <section class="project--detail-detail">
    <h2 class="title--text">
      <span v-if="unitType">{{ $t('general.unitTypeDetail') }}</span>
      <span v-else>
        <span v-if="project.listing_type === listingTypeEnum.PRIMARY">{{
          $t('general.projectDetail')
        }}</span>
        <span v-else>{{ $t('general.propertyDetail') }}</span>
      </span>
    </h2>
    <div class="column--wrapper">
      <div class="column--content" v-if="project.block_number">
        <v-icon>mdi-home-city</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('general.blockNumber') }}
          </h3>
          <div class="column--desc">{{ project.block_number }}</div>
        </div>
      </div>
      <div class="column--content" v-if="project.unit_number">
        <v-icon>mdi-home-variant-outline</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('general.unitNumberFull') }}
          </h3>
          <div class="column--desc">{{ project.unit_number }}</div>
        </div>
      </div>
      <div class="column--content" v-if="project.address">
        <v-icon>mdi-map-marker</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('general.location') }}
          </h3>
          <div class="column--desc">
            {{ project.address }}
          </div>
        </div>
      </div>
      <div class="column--content" v-if="project.category_name">
        <v-icon>mdi-home-group</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('general.categoryName') }}
          </h3>
          <div class="column--desc">
            {{ project.category_name }}
          </div>
        </div>
      </div>
      <div
        class="column--content"
        v-if="unitTypes && project.listing_type === listingTypeEnum.PRIMARY"
      >
        <v-icon>mdi-format-list-bulleted-type</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('general.typeTotal') }}
          </h3>
          <div class="column--desc">{{ unitTypes.length }} {{ $t('general.type') }}</div>
        </div>
      </div>
      <div class="column--content" v-if="!unitType && project.construction_status">
        <v-icon>mdi-list-status</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('general.constructionStatus') }}
          </h3>
          <div class="column--desc">
            <span>{{ project.construction_status }} </span>
            <span v-if="project.estimated_finish_year"
              >{{
                project.construction_status.toLowerCase() === 'selesai'
                  ? $t('general.at')
                  : $t('general.until')
              }}
              {{ project.estimated_finish_year }}</span
            >
          </div>
        </div>
      </div>
      <div class="column--content" v-if="!unitType && project.total_available_units">
        <v-icon>mdi-home-city</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('general.unitTotal') }}
          </h3>
          <div class="column--desc">
            {{ project.total_available_units }} {{ $t('general.unit') }}
          </div>
        </div>
      </div>
      <div class="column--content" v-if="(unitType && unitType.min_land_size) || project.land_size">
        <v-icon>mdi-home</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('addProject.landSize') }}
          </h3>
          <div class="column--desc" v-if="unitType">
            {{ compareMinMax(unitType.min_land_size, unitType.max_land_size) }} m2
          </div>
          <div class="column--desc" v-else>{{ project.land_size }} m2</div>
        </div>
      </div>
      <div
        class="column--content"
        v-if="(unitType && unitType.min_building_size) || project.building_size"
      >
        <v-icon>mdi-domain</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('addProject.buildingSize') }}
          </h3>
          <div class="column--desc" v-if="unitType">
            {{ compareMinMax(unitType.min_building_size, unitType.max_building_size) }} m2
          </div>
          <div class="column--desc" v-else>{{ project.building_size }} m2</div>
        </div>
      </div>
      <div
        class="column--content"
        v-if="(unitType && unitType.min_bedroom != null) || project.bedroom != null"
      >
        <v-icon>mdi-bed</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('general.bedroomNumber') }}
          </h3>
          <div class="column--desc" v-if="unitType">
            {{ unitType.min_bedroom === 0 ? $t('general.studio') : unitType.min_bedroom }}
          </div>
          <div class="column--desc" v-else>
            {{ project.bedroom === 0 ? $t('general.studio') : project.bedroom }}
          </div>
        </div>
      </div>
      <div class="column--content" v-if="(unitType && unitType.min_bathroom) || project.bathroom">
        <v-icon>mdi-shower-head</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('general.bathroomNumber') }}
          </h3>
          <div class="column--desc" v-if="unitType">{{ unitType.min_bathroom }}</div>
          <div class="column--desc" v-else>{{ project.bathroom }}</div>
        </div>
      </div>
      <div class="column--content" v-if="unitType && unitType.electricity_capacity">
        <v-icon>mdi-flash</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('general.electricity') }}
          </h3>
          <div class="column--desc">{{ unitType.electricity_capacity }} VA</div>
        </div>
      </div>
      <div class="column--content" v-if="(unitType && unitType.total_floor) || project.total_floor">
        <v-icon>mdi-stairs</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('general.totalFloor') }}
          </h3>
          <div class="column--desc" v-if="unitType">{{ unitType.total_floor }}</div>
          <div class="column--desc" v-else>{{ project.total_floor }}</div>
        </div>
      </div>
      <div class="column--content" v-if="project.floor">
        <v-icon>mdi-stairs</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('general.floor') }}
          </h3>
          <div class="column--desc">{{ project.floor }}</div>
        </div>
      </div>
      <div class="column--content" v-if="project.certificate">
        <v-icon>mdi-file-certificate-outline</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('general.certificate') }}
          </h3>
          <div class="column--desc">{{ project.certificate }}</div>
        </div>
      </div>
      <div class="column--content" v-if="project.water_source">
        <v-icon>mdi-water</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('general.waterSource') }}
          </h3>
          <div class="column--desc">{{ project.water_source }}</div>
        </div>
      </div>
      <div
        class="column--content"
        v-if="(unitType && unitType.furnishing_status) || project.furnishing_status"
      >
        <v-icon>mdi-seat</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('general.furniture') }}
          </h3>
          <div class="column--desc" v-if="unitType">{{ unitType.furnishing_status }}</div>
          <div class="column--desc" v-else>{{ project.furnishing_status }}</div>
        </div>
      </div>
      <div class="column--content" v-if="project.electrical_power">
        <v-icon>mdi-flash</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('general.electricalPower') }}
          </h3>
          <div class="column--desc">
            {{ project.electrical_power }}
          </div>
        </div>
      </div>
      <div class="column--content" v-if="project.hoek !== null">
        <v-icon>mdi-home-switch</v-icon>
        <div class="ml-2">
          <h3 class="column--title">
            {{ $t('general.hoek') }}
          </h3>
          <div class="column--desc">
            {{ project.hoek ? $t('general.yes') : $t('general.no') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

export default {
  name: 'project-detail-detail',
  mixins: [HelperMixin],
  props: ['project', 'unitTypes', 'unitType'],
  computed: {
    ...mapState({
      listingTypeEnum: (state) => state.v2.masters.listingTypeEnum,
    }),
  },
  methods: {},
};
</script>
